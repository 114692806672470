/* FOOTER */
.footer {
	width: 100%;
	background: #591e11;
	display: block;
 }

 .inner-footer {
	 width: 95%;
	 margin: auto;
	 padding: 30px 10px;
	 display: flex;
	 flex-wrap: wrap;
	 box-sizing: border-box;
	 justify-content: center;
 }

.footer-items {
	width: 25%;
	padding: 10px 20px;
	box-sizing: border-box;
	color: #fff;
}

.footer-items p {
	font-size: 16px;
	text-align: justify;
	line-height: 25px;
	color: #fff;
}

.footer-items h1 {
	color: #fff;
	font-size: 20px;
}

.footer .border1 {
	height: 3px;
	width: 40px;
	background: #d9ab82;
	color: #d9ab82;
	background-color: #d9ab82;
	border: 0px;
}

.footer ul {
	list-style: none;
	color: #fff;
	font-size: 15px;
	letter-spacing: 0.5px;	
 }

.footer ul a {
	text-decoration: none;
	outline: none;
	color: #fff;
	transition: 0.3s;
}

.footer ul a:hover {
	color: #d9ab82;
}

.footer ul li {
	margin: 10px 0;
	height: 25px;
}

.footer li i {
	margin-right: 20px;
}

.footer .social-media {
	width: 100%;
	color: #fff;
	text-align: center;
	font-size: 20px;
}

.footer .social-media a {
	text-decoration: none;
}

.footer .social-media i {
	height: 25px;
	width: 25px;
	margin: 20px 10px;
	padding: 4px;
	color: #fff;
	transition: 0.5s;
}

.footer .social-media i:hover {
	transform: scale(1.5);
}

.footer .footer-bottom {
	padding: 10px;
	background: #591e11;
	color: #fff;
	font-size: 12px;
	text-align: center;
}

.footer-bottom a {
	color: white;
	text-decoration: none;
}

.footer-bottom a:hover {
	color: #591e11;
	text-decoration: none;
}


/* for tablet mode view */

@media screen and (max-width: 1275px) {
	.footer-items {
		width: 50%;
	}
}

/* for mobile screen view */

@media screen and (max-width: 660px) {
	.footer-items {
		width: 100%;
	}
}

/* you can toggle the media screen view accordingly by changing the (max-width: px) to your convenience */

/* Thanks to Computer Conversations */