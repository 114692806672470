.contact-us {
    margin-top: 70px;
}

.contact-us button {
    padding: 14px 30px;
    text-decoration: none;
    letter-spacing: 1.2px;
    font-size: 14px;
    border: 1px solid #591e11;
    border-radius: 7px;
    margin-right: 20px;
    margin-top: 10px;
    transition: 0.15s ease-in-out;
    display: block;
}

.contact-us a.focus,
button {
    color: white;
    background: #591e11;
}

.contact-us a.focus:hover,
button:hover {
    cursor: pointer;
    color: #d9ab82;
}

.contact-us a:hover {
    background-color: #591e11;
    color: #d9ab82;
}

.contact-us a.active {
    color: #d9ab82;
    font-weight: 500;
}


.contact-us .content {

    top: 60px;

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 20px;
    background: #d9ab82;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #591e11, #d9ab82);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #591e11, #d9ab82);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.contact-us .contact {
    width: 80%;
    max-width: 1080px;
    display: flex;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    overflow: hidden;
}


.contact-us .other {
    width: 60%;
    background-image: url(../images/Contact.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.contact-us .other:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(45deg, rgb(36, 51, 66), rgba(36, 51, 66, 0.81));
}






.contact-us .form {
    width: 40%;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.contact-us .form h1 {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    color: #591e11;
    font-size: 30px;
    font-weight: 900;
}

.contact-us .form form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact-us .flex-rev {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 10px;
    width: 100%;
}

.contact-us .flex-rev input,
.flex-rev textarea {
    border: none;
    background-color: #e6e6e6;
    padding: 12px 10px;
    font-size: 16px;
    resize: none;
    margin-top: 7px;
    margin-bottom: 16px;
    border-radius: 5px;
    color: #243342;
    outline-color: #243342;
    outline-width: thin;
    -webkit-appearance: none;
}

.contact-us .flex-rev textarea {
    height: 150px;
}

.contact-us button {
    -webkit-appearance: none;
    margin-right: 0;
}


.contact-us .info {
    position: relative;
    padding: 20px;
}



.contact-us .info h2 {
    color: #d9ab82;
    font-size: 34px;
}

.contact-us .info h3 {
    color: white;
    letter-spacing: 1.2px;
    margin-top: 20px;
}

.contact-us .info p {
    color: white;
    letter-spacing: 1.2px;
    margin-top: 5px;
}

.contact-us .svg-wrap {
    display: flex;
    margin-top: 14px;
}

.contact-us .svg-wrap-phone {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
}

.contact-us .svg-wrap-phone a {
    border: 0;
    padding: 5px;
    color: white;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.contact-us .svg-wrap-phone a:hover {
    background-color: transparent;
    color: #d9ab82;
    cursor: pointer;
}



.contact-us .svg-wrap a {
    border: 0;
    padding: 0;
    display: flex;
    color: white;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.contact-us .svg-wrap a svg {
    width: 20px;
    height: 20px;
    fill: white;
    transition: fill 0.2s ease-in-out;
    margin-right: 13px;
}

.contact-us .svg-wrap a:hover svg {
    fill: #d9ab82;
    cursor: pointer;
}

.contact-us .svg-wrap a:hover {
    background-color: transparent;
    color: #d9ab82;
    cursor: pointer;
}

.contact-us form p {
    color: red;
}


.loading-image {
    width: 15%;
}

.check-icon {
    color: green;
}

@media screen and (max-width: 900px) {
    .contact-us {
        margin-top: 70px;
    }


    .contact-us .content {
        padding: 10px 0 0;

    }

    .contact-us .contact {
        width: 100%;
        flex-direction: column;
        border-radius: 0;
        box-shadow: 0px 0px 0px 0px;
    }

    .contact-us .other {
        width: 100%;
        padding: 20px 0;
    }

    .contact-us .form {
        width: 100%;
    }
}



@media screen and (max-height: 660px) {
    .contact-us .content {
        align-items: flex-start;
    }

}