/* Landing Section */
.banner {
  margin-top: 800px;
}

.showcase {
  width: 100%;
  min-height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;
  background-image: url(../images/Landing.jpg);
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 0;
  z-index: 1;
}

.showcase:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.showcase.active {
  right: 300px;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;

}

.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

.text a {
  letter-spacing: 1px;
  border: 4px solid white;
  color: white;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color .35s ease, background .35s ease;
  transition: border-color .35s ease, background .35s ease;
}

.text a:before {
  background-color: white;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.text a:hover:before {
  top: 0;
}

.text a:hover {
  color: #2A4556;
  transition: 0.25s;
}

.text a:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.text a {
  padding: .9375rem 1.875rem;
}

.text2 a {
  letter-spacing: 1px;
  border: 4px solid white;
  color: white;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color .35s ease, background .35s ease;
  transition: border-color .35s ease, background .35s ease;
}

.text2 a:before {
  background-color: white;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.text2 a:hover:before {
  top: 0;
}

.text2 a:hover {
  color: #2A4556;
  transition: 0.25s;
}

.text2 a:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.text2 a {
  padding: .9375rem 1.875rem;
}



.text2 h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text2 h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.text2 p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}


.text {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.text2 {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.restPage {
  position: relative;
  margin-top: 100vh;
  z-index: 2;
  transition: 0.3s ease-in-out;
}

.paragraphs {
  background-color: white;
}

@media (max-width: 991px) {

  .showcase,
  .showcase header {
    padding: 40px;
  }

  .text h2 {
    font-size: 3em;
  }

  .text h3 {
    font-size: 2em;
  }

  .text2 h2 {
    font-size: 3em;
  }

  .text2 h3 {
    font-size: 2em;
  }
}

/* About section */
.about-section {
  background: url(../images/About.jpg) no-repeat left;
  background-size: 55%;
  background-color: white;
  overflow: hidden;
  padding: 100px 0;
}


.inner-container {
  width: 55%;
  float: right;
  background-color: white;
  padding: 150px;
}

.inner-container h1 {
  color: #591e11;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
}

.about-section-text {
  font-size: 1.1em;
  color: #545454;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 30px;
}

.about-section a {
  letter-spacing: 1px;
  border: 4px solid #591e11;
  color: #591e11;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color .35s ease, background .35s ease;
  transition: border-color .35s ease, background .35s ease;
}

.about-section a:before {
  background-color: #591e11;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.about-section a:hover:before {
  top: 0;
}

.about-section a:hover {
  color: white;
  transition: 0.25s;
}

.about-section a:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.about-section a {
  padding: .9375rem 1.875rem;
}


@media screen and (max-width:1200px) {
  .inner-container {
    padding: 80px;
  }
}

@media screen and (max-width:1000px) {
  .about-section {
    background-size: 100%;
    padding: 100px 40px;
  }

  .inner-container {
    width: 100%;
  }
}

@media screen and (max-width:600px) {
  .about-section {
    padding: 0;
  }

  .inner-container {
    padding: 40px;
  }
}

/* Testimonials Section */

.testimonial-section {
  padding: 80px 0 80px;
  background: #d9ab82;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #591e11, #d9ab82);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #591e11, #d9ab82);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.testimonial-section h1 {
  color: white;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.testimonial-section .testi-item {
  background-color: white;
  margin: 30px;
  border-radius: 50px 50px 50px 0px;
}

.testimonial-section .testi-item p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  padding: 30px 30px 0 30px;
  margin: 0 0 10px;
  font-style: italic;
}

.testimonial-section .testi-item p .fa-quote-left {
  margin-right: 5px;
  color: #591e11;
}

.testimonial-section .testi-item p .fa-quote-right {
  margin-left: 5px;
  color: #591e11;
}

.testimonial-section .testi-comment .stars {
  padding-left: 30px;
  margin: 0 0 20px;
}

.testimonial-section .testi-comment .stars li {
  display: inline-block;
  margin: 0 1px;
}

.testimonial-section .testi-comment .stars li i {
  font-size: 15px;
  color: tomato;
}

.testimonial-section .client-info {
  position: relative;
  padding-left: 115px;
  padding-top: 5px;
  height: 90px;
}


.testimonial-section .client-info h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 2px;
  color: #591e11;
}

/* Link to leagal library section */

.external-link {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.external-link img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  background-color: #CCD19E;
}

.fx-card {
  background-color: #fff;
  margin-right: auto;
  margin-left: auto;
  max-width: 1293px;
}

.fx-card__info {
  padding: 20px;

}

.fx-card__title {
  color: #591e11;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 30px;
  font-weight: 900;
}

.fx-card__text {
  font-size: 1.1em;
  color: #545454;
  line-height: 20px;
  text-align: justify;
}

.external-link a {
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
  border: 4px solid #591e11;
  color: #591e11;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color .35s ease, background .35s ease;
  transition: border-color .35s ease, background .35s ease;
}

.external-link a:before {
  background-color: #591e11;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.external-link a:hover:before {
  top: 0;
}

.external-link a:hover {
  color: white;
  transition: 0.25s;
}

.external-link a:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.external-link a {
  padding: .9375rem 1.875rem;
}


@media (min-width: 600px) {
  .fx-card__info {
    padding: 0 20px;
  }

  .fx-card {
    display: flex;
  }

  .fx-card__img {
    order: 1;
    flex: 0 0 300px;
  }

  .fx-card__info {
    order: 0;
    align-self: center;
  }

}

@media(min-width: 800px) {
  .fx-card__info {
    padding: 0 40px;
  }
}

/* Members of and ranked by section */
.member-of {
  background-color: white;
  padding: 80px 0px 0px 0px;
}

.member-of h1 {
  color: #591e11;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card1 {
  background-color: #006898;
}

.card1 img {
  margin-top: 30%;
}

.card2 .card_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card2 img {
  width: 250px;
}

.card3 img {
  margin-top: 25%;
  width: 300px;
}

.card4 {
  background-color: #1c3319;
}

.card4 img {
  margin-top: 20%;
  width: 300px;
}


.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

.ranked-by {
  background-color: white;
  padding-bottom: 80px;
}

.ranked-by h1 {
  color: #591e11;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

@media all and (max-width: 500px) {
  .card-list {
    flex-direction: column;
  }
}

@media screen and (max-width:280px) {
  .card {
    margin: 30px auto;
    width: 200px;
    height: 200px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }

  .card1 img {
    margin-top: 30%;
    width: 200px;
  }


  .card2 img {
    margin-top: 40%;
    width: 200px;
  }



  .card3 img {
    margin-top: 25%;
    width: 200px;
  }


  .card4 img {
    margin-top: 20%;
    width: 200px;
  }

}