* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

body ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.16);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}

