.testimonials-body {
  background-color: #fefefe;
}

.testimonial-container
{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 60em;
  *zoom: 1;
}

.Testimonials-intro h1{
  color: white;
  text-align: center;
  padding-top: 25vh;
  padding-bottom: 25vh;
  background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3));
}

.Testimonials-intro {
  margin-top: 80px;
}


.grid-testimonials
{
  overflow: hidden;
  padding-top: 3.125em
}
.grid-testimonials li
{
  float: left;
  list-style-type: none;
  padding-bottom: 3.125em
}
.grid-testimonials p
{
  line-height: 1.5em;
  margin: 0
}
.grid-testimonials-citation
{
  color: #591e11;
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 2em;
  text-transform: uppercase;
  text-align: center
}

.grid-testimonials i {
  color: #591e11;
}

.grid-testimonials li div
{
  border: 10px solid #ebebeb;
  padding: 2.125em 7.638888888888889%;
  position: relative
}
.grid-testimonials .quote-arrow
{
	background: #fefefe;
	border-right:10px solid #ebebeb;
	border-bottom:10px solid #ebebeb;
	content: '';
	display: block;
	position: absolute;
	bottom: -32px;
	left: 50%;
	margin-left: -25px;
	width: 40px;
	height: 40px;
	-moz-transform:rotate(45deg);
	-webkit-transform:rotate(45deg);
}

.more-reviews-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-reviews h1 {
  text-align: center;
  color: #591e11;
  font-size: 30px;
  font-weight: 900;
}

.more-reviews a {
  margin: 2%;
  letter-spacing: 1px;
  border: 4px solid #591e11;
  color: #591e11;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color .35s ease, background .35s ease;
  transition: border-color .35s ease, background .35s ease;
}

.more-reviews a:before {
  background-color: #591e11;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;
}

.more-reviews a:hover:before {
  top: 0;
}

.more-reviews a:hover{
color: white;
transition:0.25s;
}

.more-reviews a:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.more-reviews a {
  padding: .9375rem 1.875rem;
}
@media screen and (min-width: 50em) 
{
	.grid-testimonials li
  {
    margin-right: 5%;
    width: 30%
  }
	.grid-testimonials li:nth-child(3n)
  {
    margin-right: 0
  }
}
