.team-intro h1 {
  color: white;
  text-align: center;
  padding-top: 25vh;
  padding-bottom: 25vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
}

.team-intro {
  margin-top: 80px;
}


.Team body {
  margin: 0;
  padding: 20px;
  min-height: 100vh;
  background-color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Team .flip-card {
  margin: 20px;
  position: relative;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.Team .flip-card-front,
.flip-card-back {
  width: 380px;
  min-height: 600px;
  height: auto;
  background-color: #000000;
  background-image: url(../images/Tamirat.jpg);
  background-size: cover;
  background-position: center;
  text-align: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.Team .flip-card:nth-of-type(2) .flip-card-front,
.flip-card:nth-of-type(2) .flip-card-back {
  background-image: url(../images/Marina.jpg);
}

.Team .flip-card:nth-of-type(3) .flip-card-front,
.flip-card:nth-of-type(3) .flip-card-back {
  background-image: url(../images/Mekdem.jpg);
}

.Team .flip-card:nth-of-type(4) .flip-card-front,
.flip-card:nth-of-type(4) .flip-card-back {
  background-image: url(../images/Melfia.jpg);
}

.Team .flip-card:nth-of-type(5) .flip-card-front,
.flip-card:nth-of-type(5) .flip-card-back {
  background-image: url(../images/Yeabsira.jpg);
}

.Team .flip-card:nth-of-type(6) .flip-card-front,
.flip-card:nth-of-type(6) .flip-card-back {
  background-image: url(../images/Meron.jpg);
}

.Team .flip-card:nth-of-type(7) .flip-card-front,
.flip-card:nth-of-type(7) .flip-card-back {
  background-image: url(../images/Fitsum.jpg);
}

.Team .flip-card:nth-of-type(8) .flip-card-front,
.flip-card:nth-of-type(8) .flip-card-back {
  background-image: url(../images/Girma.jpg);
}

.Team .flip-card:nth-of-type(9) .flip-card-front,
.flip-card:nth-of-type(9) .flip-card-back {
  background-image: url(../images/Wondwossen.jpg);
}

.Team .flip-card:nth-of-type(10) .flip-card-front,
.flip-card:nth-of-type(10) .flip-card-back {
  background-image: url(../images/Nebiat.jpg);
}

.Team .flip-card:nth-of-type(11) .flip-card-front,
.flip-card:nth-of-type(11) .flip-card-back {
  background-image: url(../images/Getenesh.jpg);
}


.Team .flip-card-front {
  transform: rotateY(0deg);
}

.Team .flip-card-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.Team .flip-card-front::after,
.flip-card-back::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0a0a0a;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.Team .flip-card-front .inner,
.flip-card-back .inner {
  width: 100%;
  padding: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  z-index: 2;
}

.Team .inner .icon {
  width: 80px;
  margin-bottom: 20px;
}

.Team .inner h3 {
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  text-transform: capitalize;
}

.Team .inner p {
  color: #ffffff;
  font-size: 16px;
  line-height: 28px;
}

.Team .flip-card:hover .flip-card-front {
  transform: rotateY(-180deg);
}

.Team .flip-card:hover .flip-card-back {
  transform: rotateY(0deg);
}

@media screen and (max-width:400px) {

  .Team .flip-card-front,
  .flip-card-back {
    width: 350px;
    min-height: 600px;
    height: auto;
    background-color: #000000;
    background-image: url(../images/Tamirat.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
}

@media screen and (max-width:280px) {

  .Team .flip-card-front,
  .flip-card-back {
    width: 280px;
    min-height: 600px;
    height: auto;
    background-color: #000000;
    background-image: url(../images/Tamirat.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
}