.about-page{
    background: #fefefe;
}


.practice-intro h1{
    color: white;
    text-align: center;
    padding-top: 25vh;
    padding-bottom: 25vh;
    background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3));
}

.practice-intro {
    margin-top: 80px;
}


.practice-page .desc-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3% 25% 3% 25%;
}

.practice-page .desc-box .content h3{
    font-size: 1.7rem;
}
.practice-page .desc-box .content p{
    font-size: 1.1rem;
    margin: 2rem 0;
    color:#666;
}

hr.title-page-divider {
    border-style: inset;
    border-width: 1px;
    width: 80%;
    box-shadow: 2px 2px black;
    color: #591e11;
    opacity: 0.3;
  }

.practice-page .services {
 margin-top: 35px;
  }

@media screen and (max-width: 914px) {
    .practice-page .desc-box{
        padding: 5% 5% 5% 5%;
    }
}

@media screen and (max-width: 990px) {
    .practice-page .desc-box{
        padding: 5% 5% 5% 5%;
    }
}
